import classNames from 'classnames/bind';

import withComponentName from '../../../decorators/withComponentName';

import finpromptLogoWhite from '../../../assets/finprompt/finprompt-logo-white.png';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const Footer = ({ showModal }) => {
  const handleClick = (link) => {
    window.location.href = link;
  };

  return (
    <footer className={cx('footer', { 'overlap-chatbot-input': showModal })}>
      <div className={cx('footer__wrapper')}>
        <div className={cx('footer__content')}>
          <div className={cx('footer__logo', 'footer-logo')}>
            <div className={cx('footer-logo__image')}>
              <img
                src={finpromptLogoWhite}
                alt="finprompt logo"
              />
              <div className={cx('footer-logo__text')}>FinPrompt.ai</div>
            </div>
            <div className={cx('footer-logo__copyright')}>
              <div className={cx('footer-logo__copyright--first-row')}>
                A
                {' '}
                <a href="https://cityfalcon.ai" target="_blank" rel="noreferrer">CityFALCON</a>
                {' '}
                Solution
              </div>
              <div className={cx('footer-logo__copyright--second-row')}>© 2024 All Rights Reserved</div>
            </div>
          </div>
          <div className={cx('footer__navigation', 'footer-navigation')}>
            <ul className={cx('footer-navigation__list')}>
              <li className={cx('footer-navigation__list-item')}>
                <span onClick={() => handleClick('/privacy')}>
                  Privacy Policy
                </span>
              </li>
              <li className={cx('footer-navigation__list-item')}>
                <span onClick={() => handleClick('/terms')}>
                  Terms of Use
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default withComponentName(Footer);
