import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import Preloader from '../../Preloader';

import withComponentName from '../../../decorators/withComponentName';

import Header from '../Header';
import FinPromptPageContent from './FinPromptPageContent';
import NoticeBarConfirmEmail from '../NoticedBarConfirmEmail';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const HomePage = () => {
  const isLoadingUserInfo = useSelector((state) => state.userPreferencesReducer.loadingUserInfo);
  const user = useSelector(
    (state) => state.userPreferencesReducer.user,
  );
  const hasEmailProp = user?.hasOwnProperty('email_confimed');

  return (
    <div className={cx('finprompt', { 'email-not-confirmed': user && hasEmailProp && !user.email_confimed })}>
      <Header />
      <NoticeBarConfirmEmail />
      {isLoadingUserInfo ? (
        <Preloader loading centered />
      ) : (
        <div className={cx('finprompt__wrapper')}>
          <FinPromptPageContent />
        </div>

      )}
    </div>
  );
};

export default withComponentName(HomePage);
