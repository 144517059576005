import classNames from 'classnames/bind';
import { createPortal } from 'react-dom';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import withComponentName from '../../../../decorators/withComponentName';

import { setForgotPasswordCustomModalOpened } from '../../../../actions/authentication.actions';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const CustomModalFullPageFinPrompt = (props) => {
  const {
    isFinpromptPages, portalTarget, hasBigScreen = true,
    showModal, children, disabledHandleClose,
  } = props;
  const dispatch = useDispatch();

  const modalWrapperRef = useRef(null);

  const setModalOpened = (newState) => {
    dispatch(setForgotPasswordCustomModalOpened(newState));
  };

  const handleCloseModal = (e) => {
    if (modalWrapperRef?.current?.contains(e?.target)
    || e?.target?.dataset?.forgotPassword) return;

    setModalOpened(false);
  };

  useEffect(() => {
    if (!showModal || disabledHandleClose) return;

    document.addEventListener('click', handleCloseModal);

    return () => document.removeEventListener('click', handleCloseModal);
  }, [showModal, disabledHandleClose]);

  return showModal ? (
    createPortal(
      (
        <div
          ref={modalWrapperRef}
          className={cx('custom-modal-full-page-finprompt', { finprompt: isFinpromptPages }, { big_screen: hasBigScreen })}
        >
          <div className={cx('custom-modal-full-page-finprompt__wrapper')}>
            {children}
          </div>
        </div>
      ),
      portalTarget || document.body,
    )
  ) : null;
};

export default withComponentName(CustomModalFullPageFinPrompt);
