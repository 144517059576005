import classNames from 'classnames/bind';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import UserLoginComponent from '../../Header/UserLoginComponent';
import HeaderLeftSide from '../../Header/MainHeaderComponent/HeaderLeftSide';
import MobileBurgerMenu from './MobileBurgerMenu';

import withComponentName from '../../../decorators/withComponentName';

import FinpromptLogo from '../../../assets/finprompt/finprompt-logo.png';

import BetaTag from '../../Shared/BetaTag';

import useSizes from '../../../hooks/sizes';
import usePiwik from '../../../hooks/usePiwik';

import headerFinPromptLinks from '../../../data/headerData/headerFinPromptLinks';
import UserLoginComponentLinksFinPrompt from '../../../data/headerData/UserLoginComponentLinksFinPrompt';

import getUserToken from '../../../helpers/getUserToken';
import getUserInfo from '../../../helpers/getUserInfo';

import { loadingUserInfo, setUserData } from '../../../actions/preferencesActions';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const Header = () => {
  const dispatch = useDispatch();
  const { piwikEnabled } = usePiwik();
  const { width } = useSizes();

  const [isLoading, setIsLoading] = useState(true);

  const activeWatchlistId = useSelector((state) => state.storyFilters.active_watchlist_id);
  const user = useSelector((state) => state.userPreferencesReducer.user);

  useEffect(() => {
    const token = getUserToken();

    if (token && !user) {
      getUserInfo()
        .then((res) => {
          dispatch(setUserData(res?.data));
        }).catch((error) => {
          console.error(error.message);
        })
        .finally(() => {
          setIsLoading(false);
          dispatch(loadingUserInfo(false));
        });
    } else {
      setIsLoading(false);
      dispatch(loadingUserInfo(false));
    }
  }, [user]);

  const navMenuRef = useRef(null);

  return (
    <header className={cx('header')}>
      <div className={cx('header__wrapper')}>
        <div className={cx('header__content')}>
          <MobileBurgerMenu items={headerFinPromptLinks} />
          <div className={cx('header__logo', 'header-logo')}>
            <div className={cx('header-logo__image')}>
              <img
                src={FinpromptLogo}
                alt="finprompt logo"
              />
            </div>
            <div className={cx('header-logo__content')}>
              <div className={cx('header-logo__text')}>FinPrompt.ai</div>
              <BetaTag className={cx('tag', 'beta')} />
            </div>
          </div>
          <nav className={cx('header__navigation', 'header-navigation')}>
            <ul className={cx('header-navigation__list')}>
              <HeaderLeftSide
                navMenuRef={navMenuRef}
                items={headerFinPromptLinks}
                isFinpromptPages
              />
              {isLoading ? null : (
                <UserLoginComponent
                  activeWatchlistId={activeWatchlistId}
                  width={width}
                  piwikEnabled={piwikEnabled}
                  isRailsContext={false}
                  isFinpromptPages
                  hasBigScreenDesign
                  items={UserLoginComponentLinksFinPrompt}
                />
              )}
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default withComponentName(Header);
