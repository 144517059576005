import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';

import * as finpromptActions from '../../../actions/finprompt.actions';

import resendEmailConfirmation from '../../../helpers/resendEmailConfirmation';

import useSizes from '../../../hooks/sizes';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const NoticeBarConfirmEmail = () => {
  const dispatch = useDispatch();
  const actions = useMemo(() => (
    bindActionCreators({
      ...finpromptActions,
    }, dispatch)
  ), [dispatch]);

  const user = useSelector((state) => state.userPreferencesReducer.user);
  const heightNoticebarConfirmEmail = useSelector(
    (state) => state.finprompt.heightNoticebarConfirmEmail,
  );

  const { width } = useSizes();
  const hasEmailProp = user?.hasOwnProperty('email_confimed');

  const [emailWasResent, setEmailWasResent] = useState(false);

  const handleResendClick = async () => {
    try {
      const res = await resendEmailConfirmation('finprompt');
      if (!res?.ok) return;
      setEmailWasResent(true);
    } catch (error) {
      console.log('resendEmailConfirmation-> error:', error);
    }
  };

  useEffect(() => {
    let timer;
    if (emailWasResent) {
      timer = setTimeout(() => {
        setEmailWasResent(false);
      }, 3000);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [emailWasResent]);

  const onRefChanged = useCallback((node) => {
    if (!user || !hasEmailProp || user.email_confimed) return null;

    if (node) actions.saveHeightNoticebarConfirmEmail(node.clientHeight);
  }, [width, user]);

  if (!user || !hasEmailProp || user.email_confimed) return null;

  return (
    <div className={cx('notice-bar__inner')}>
      <div style={{ height: `${heightNoticebarConfirmEmail}px`, minHeight: `${heightNoticebarConfirmEmail}px` }} />
      <div
        ref={onRefChanged}
        className={cx('notice-bar')}
      >
        <div className={cx('notice-bar__wrapper')}>
          <div className={cx('notice-bar__content')}>
            {emailWasResent ? (
              <div className={cx('notice-bar__success')}>
                <FontAwesomeIcon icon={faCircleCheck} />
                Email sent successfully.
              </div>
            ) : (
              <>
                Please confirm your email to enjoy uninterrupted access. Having trouble confirming?
                {' '}
                <span
                  className={cx('notice-bar__link')}
                  onClick={handleResendClick}
                >
                  Resend confirmation email
                </span>
                {' or '}
                <span
                  className={cx('notice-bar__link')}
                  onClick={() => { window.location.href = '/email/edit'; }}
                >
                  Change email.
                </span>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoticeBarConfirmEmail;
