import classNames from 'classnames/bind';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import withComponentName from '../../../../decorators/withComponentName';
import permissionsDecorator from '../../../../decorators/permissionsDecorator';

import Footer from '../../Footer';
import ForgotPasswordContentFinPrompt from '../../AuthMainWrapperFinPrompt/Modals/ForgotPasswordContentFinPrompt';
import CustomModalFullPageFinPrompt from '../../AuthMainWrapperFinPrompt/Modals/CustomModalFullPageFinPrompt';
import DemoVideoWelcomeScreen from '../../DemoVideoWelcomeScreen';
import FinpromptChatbotComponent from './FinpromptChatbotComponent';

import useSizes from '../../../../hooks/sizes';

import Styles from '../styles.module.scss';

const cx = classNames.bind(Styles);

const FinPromptPageContent = (props) => {
  const contentWrapperRef = useRef(null);
  const { width, height } = useSizes();

  const user = useSelector((state) => state.userPreferencesReducer.user);
  const heightNoticebarConfirmEmail = useSelector(
    (state) => state.finprompt.heightNoticebarConfirmEmail,
  );
  const showForgotPassModal = useSelector(
    (state) => state.authentication.forgotPasswordCustomModalOpened,
  );

  const [screenHeight, setScreenHeight] = useState(0);

  useEffect(() => {
    setScreenHeight(window?.innerHeight || 0);
  }, [user, height]);

  const headerHeight = ((width < 2800) && 80) || 160;
  const calculatedValueHeight = headerHeight + heightNoticebarConfirmEmail;
  const styleProperty = `calc(${(screenHeight && `${screenHeight}px`) || '100vh'} - ${calculatedValueHeight}px)`;

  return (
    <main
      className={cx('finprompt__content')}
      id="finprompt-chatbot-content"
    >
      {!user ? (
        <div className={cx('finprompt__content__demo-wrapper')}>
          <DemoVideoWelcomeScreen />
        </div>
      ) : (
        <>
          <div
            className={`${cx('finprompt__content-wrapper')} widget__inner-wrapper`}
            ref={contentWrapperRef}
            style={{
              minHeight: styleProperty,
              height: styleProperty,
              maxHeight: styleProperty,
            }}
          >
            <FinpromptChatbotComponent
              isFinpromptPages
              datePositionFinPrompt={calculatedValueHeight}
              contentWrapperRef={contentWrapperRef}
            />
          </div>
          <CustomModalFullPageFinPrompt
            forgotPassword
            isFinpromptPages
            showModal={showForgotPassModal}
            disabledHandleClose
          >
            <ForgotPasswordContentFinPrompt
              isFinpromptPages
            />
          </CustomModalFullPageFinPrompt>
        </>
      )}
      <Footer
        showModal={showForgotPassModal}
        {...props}
      />
    </main>
  );
};

export default withComponentName(
  permissionsDecorator(FinPromptPageContent),
);
