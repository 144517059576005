import classNames from 'classnames/bind';
import {
  useEffect, useRef, useState,
} from 'react';
import { useSelector } from 'react-redux';

import withComponentName from '../../../../decorators/withComponentName';

import BurgerIcon from '../../../../assets/finprompt/burger.svg';
import Times from '../../../../assets/finprompt/times.svg';

import ComingSoon from '../../../Shared/ComingSoon';

import useSizes from '../../../../hooks/sizes';
import useFinprompt from '../../../../providers/FinpromptProvider/useFinprompt';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const MobileBurgerMenu = ({ items }) => {
  const user = useSelector((state) => state.userPreferencesReducer.user);

  const { width } = useSizes();
  const { isFinpromptPages } = useFinprompt();

  const [opened, setOpened] = useState(false);

  const burgerRef = useRef(null);
  const dropdownRef = useRef(null);

  const handleClick = (e) => {
    if ((!opened && burgerRef.current.contains(e?.target))
    || (opened && !burgerRef.current.contains(e?.target))
    || (opened && burgerRef.current.contains(e?.target) && !dropdownRef.current.contains(e?.target))) {
      setOpened(!opened);
    }
  };

  const handleMenuItemClick = (item) => {
    if (!item.comingSoon) window.location.href = item.pathname;
  };

  useEffect(() => {
    if (width > 1024) return;

    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [opened, width]);

  // show burger menu only on mobiles
  if (width > 1024) return null;

  return (
    <div
      ref={burgerRef}
      className={cx('header__burger', 'burger', { opened })}
    >
      {opened ? (<img src={Times} alt="burger menu icon" />) : (<img src={BurgerIcon} alt="burger menu icon" />)}
      <div
        ref={dropdownRef}
        className={cx('burger__dropdown', 'dropdown-burger', { opened })}
      >
        {items.map((item) => (
          <li
            onClick={() => handleMenuItemClick(item)}
            key={item.menuKey}
            className={cx('dropdown-burger__link', {
              active: (isFinpromptPages && !user && (item.pathname === '/demo')),
            })}
          >
            <span>{item.menuTitle}</span>
            {item.comingSoon && <ComingSoon position="right" />}
          </li>
        ))}
      </div>
    </div>
  );
};

export default withComponentName(MobileBurgerMenu);
