// env
import getUserToken from './getUserToken';

export const resendEmailConfirmation = async (platform = 'cityfalcon') => fetch(`/webapi/v1/users/resend_email_confirmation?api_key=${getUserToken()}`, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    landing_platform: platform,
  }),
}).catch((error) => {
  console.log(error.message);
});

export default resendEmailConfirmation;
