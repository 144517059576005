export const headerFinPromptLinks = [
  {
    menuTitle: 'About Us',
    menuKey: 'aboutUsDropdown',
    pathname: '/aboutus',
    opened: false,
    menuSubItems: [],
    defaultTab: '',
    links: [],
    tabsData: [],
    comingSoon: false,
  },
  {
    menuTitle: 'Contact Us',
    menuKey: 'contactUsDropdown',
    pathname: '/contact-us',
    opened: false,
    menuSubItems: [],
    defaultTab: '',
    links: [],
    tabsData: [],
    comingSoon: false,
  },
  {
    menuTitle: 'API Access',
    menuKey: 'apiAccessDropdown',
    pathname: '/api-access',
    opened: false,
    menuSubItems: [],
    defaultTab: '',
    links: [],
    tabsData: [],
    comingSoon: true,
  },
  {
    menuTitle: 'View Demo',
    menuKey: 'viewDemo',
    pathname: '/demo',
    opened: false,
    menuSubItems: [],
    defaultTab: '',
    links: [],
    tabsData: [],
    comingSoon: false,
  },
];

export default headerFinPromptLinks;
