// core
import classNames from 'classnames/bind';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import {
  useEffect,
  useMemo, useRef, useState,
} from 'react';
import { bindActionCreators } from 'redux';

import * as authActions from '../../../actions/authentication.actions';
import * as finpromptActions from '../../../actions/finprompt.actions';

import WistiaPlayer from '../../Shared/WistiaPlayer';
import ProgressBar from './ProgressBar';

import Styles from './styles.module.scss';

import useSizes from '../../../hooks/sizes';

const cx = classNames.bind(Styles);

const videoLink = 'https://cityfalcon.wistia.com/medias/ruie8y4u3u';
const videoLinkMobile = 'https://cityfalcon.wistia.com/medias/xsp6kvm441';

const DemoVideoWelcomeScreen = () => {
  const dispatch = useDispatch();
  const actions = useMemo(() => (
    bindActionCreators({
      ...authActions,
      ...finpromptActions,
    }, dispatch)
  ), [dispatch]);

  const { width: screenWidth } = useSizes();

  const optionsConfigVideo = {
    autoPlay: true,
    playbackRateControl: true,
    playbar: true,
    playButton: true,
    settingsControl: true,
    muted: true,
    wmode: 'transparent',
  };

  const OnTimeChangeWasAdded = useRef(null);
  const [ref, setRef] = useState(null);
  const [progress, setProgress] = useState(0);
  const [videoEnded, setVideoEnded] = useState(false);

  const handleReplayDemoClick = () => {
    if (!videoEnded) {
      actions.setFinPromptAuthModalOpened(true);
    } else {
      ref?.wistiaApi.container.querySelector('video')?.play();
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (ref?.wistiaApi && !OnTimeChangeWasAdded.current) {
        ref?.wistiaApi?.bind?.('timechange', (t) => {
          setProgress((t / ref?.wistiaApi?.duration()) * 100);
        });
        ref?.wistiaApi?.bind?.('end', () => {
          setVideoEnded(true);
        });
        ref?.wistiaApi?.bind?.('play', () => {
          setVideoEnded(false);
        });
        OnTimeChangeWasAdded.current = true;
      }
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [ref]);

  return (
    <div
      className={cx('welcome-screen-demo')}
    >
      <div className={cx('welcome-screen-demo__intro-message')}>
        Introducing our AI chatbot that can answer all your finance and business related questions
      </div>
      <WistiaPlayer
        ref={(newRef) => setRef(newRef)}
        className={cx('welcome-screen-demo__video')}
        url={isMobile ? videoLinkMobile : videoLink}
        withoutPopover
        style={{
          width: '100%',
          maxWidth: (screenWidth >= 1024 && '65%') || undefined,
          minWidth: screenWidth >= 768 ? '768px' : undefined,
          margin: '0 auto',
          height: '100%',
        }}
        controls
        config={{
          options: isMobile
            ? {
              ...optionsConfigVideo,
              playsinline: false,
            }
            : optionsConfigVideo,
        }}
      />
      <div className={cx('welcome-screen-demo__footer')}>
        <ProgressBar
          bgcolor="#CAC5E8"
          bgContainerColor="#F0EDFF"
          completed={progress}
          handleClick={handleReplayDemoClick}
          screenWidth={screenWidth}
          textMessage={videoEnded ? 'Replay Demo' : 'Skip Demo'}
        />
        <div
          className={cx('welcome-screen-demo__get-started-wrapper')}
        >
          <span
            onClick={() => actions.setFinPromptAuthModalOpened(true)}
            className={cx('welcome-screen-demo__get-started-button')}
          >
            Sign In / Register to Get Started
          </span>
        </div>
      </div>
    </div>
  );
};

export default DemoVideoWelcomeScreen;
