export const UserLoginComponentLinksFinPrompt = [
  {
    title: 'Account',
    menuKey: 'account',
    // TODO change route after separate FinPrompt project will be created
    route: '#',
    comingSoon: true,
  },
];

export default UserLoginComponentLinksFinPrompt;
