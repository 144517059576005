import { useMemo } from 'react';
import { BrowserRouter, StaticRouter, Route } from 'react-router-dom';
import { Provider } from 'react-redux';

import PiwikContext from '../../services/piwikContext';
import FinpromptProvider from '../../providers/FinpromptProvider';

import HomePage from './HomePage';

import withComponentName from '../../decorators/withComponentName';

import configureStore from '../../store/store';

import ResetPassword from '../ResetPassword';
import PrivacyNoticeBar from '../PrivacyNoticeBar';

const PiwikProvider = PiwikContext.Provider;
const isBrowser = typeof window !== 'undefined';

const Router = isBrowser ? BrowserRouter : StaticRouter;

const FinPromptProject = ({ railsContext, ...props }) => {
  const { piwikEnabled, sourcesEnabled } = props;

  const piwikProviderValue = useMemo(() => ({
    piwikEnabled,
    sourcesEnabled,
  }), [piwikEnabled, sourcesEnabled]);

  const routerProps = isBrowser ? {
    forceRefresh: true,
  } : {
    location: railsContext.location,
  };

  return (
    <Provider store={configureStore(props)}>
      <PiwikProvider value={piwikProviderValue}>
        <FinpromptProvider>
          <Router {...routerProps}>
            <ResetPassword isFinpromptPages />
            <PrivacyNoticeBar isFinpromptPages />

            <Route
              path="/*"
              component={HomePage}
            />
          </Router>
        </FinpromptProvider>
      </PiwikProvider>
    </Provider>
  );
};

export default withComponentName(FinPromptProject);
