import classNames from 'classnames/bind';
import axios from 'axios';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft';
import { faCircleCheck } from '@fortawesome/pro-light-svg-icons/faCircleCheck';

import Alert from '../../../Shared/ReactAlert';

import withComponentName from '../../../../decorators/withComponentName';

import AccountSvc from '../../../../services/dbServices/AccountSvc';

import { setFinPromptAuthModalOpened, setForgotPasswordCustomModalOpened } from '../../../../actions/authentication.actions';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const ForgotPasswordCustomModalFinPrompt = (props) => {
  const {
    isFinpromptPages, hasBigScreen = true,
  } = props;
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const modalWrapperRef = useRef(null);

  const handleBackButton = () => {
    dispatch(setForgotPasswordCustomModalOpened(false));
    dispatch(setFinPromptAuthModalOpened(true));
  };

  const submitForgotPassword = async () => {
    try {
      const data = await AccountSvc.resetPassword(email);
      if (data) {
        setSuccessMessage('Sent. Please check your email.');
        return Alert.info(
          'You will receive an email with instructions about how to reset your password in a few minutes.',
          {
            position: 'top',
            effect: 'stackslide',
            timeout: 3000,
            offset: 50,
          },
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onChange = ({ target }) => {
    setEmail(target?.value);

    setDisabled(target?.value?.length < 1);

    if (error.length) {
      setError('');
    }
  };

  const submitForm = (e) => {
    e.preventDefault();

    if (email.indexOf('@') === -1 || email.indexOf('.') === -1) {
      setError('Please enter a valid email address');

      return Alert.info(
        'Please enter a valid email address',
        {
          position: 'top',
          effect: 'stackslide',
          timeout: 3000,
          offset: 50,
        },
      );
    }

    axios.get('/users/email_present.json', {
      params: {
        user_email: email,
      },
    }).then(({ data }) => {
      if (data.email_present === false) {
        setError('Email not found');

        return Alert.info(
          'Email not found',
          {
            position: 'top',
            effect: 'stackslide',
            timeout: 3000,
            offset: 50,
          },
        );
      }
      submitForgotPassword();
    })
      .catch(() => {
        setError('Unknown server error. Please try again.');
        Alert.info(
          'Unknown server error. Please try again.',
          {
            position: 'top',
            effect: 'stackslide',
            timeout: 3000,
            offset: 50,
          },
        );
      });
  };

  return (
    <div
      ref={modalWrapperRef}
      className={cx('forgot-pass', { finprompt: isFinpromptPages }, { big_screen: hasBigScreen })}
    >
      <div className={cx('forgot-pass__wrapper')}>
        <div className={cx('forgot-pass__header')}>
          <span
            className={cx('forgot-pass__back-block')}
            onClick={() => handleBackButton()}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>Back</span>
          </span>
          <h4 className={cx('forgot-pass__title')}>Forgot Password?</h4>
        </div>
        <p className={cx('forgot-pass__text')}>Enter the email you used for FinPrompt / CityFALCON.</p>
        <p className={cx('forgot-pass__text')}>
          Signed up for CF using another platform?
          {' '}
          <a
            className={cx('forgot-pass__read-more-link')}
            target="_blank"
            href="https://www.cityfalcon.ai/help/articles/4405932982417"
            rel="noreferrer"
          >
            Read here
          </a>
          .
        </p>
        <form
          className={cx('forgot-pass__form')}
          onSubmit={(e) => submitForm(e)}
        >
          <label
            className={cx('forgot-pass__label', { error: error?.length > 0 })}
            htmlFor="forgot-pass-input"
          >
            <input
              className={cx('forgot-pass__input', { error: error?.length > 0 })}
              type="email"
              onChange={onChange}
              placeholder="Email"
              id="forgot-pass-input"
            />
            {error?.length ? (
              <span className={cx('forgot-pass__error')}>{error}</span>
            ) : null}
          </label>
          <button
            type="submit"
            className={cx('forgot-pass__submit', { disabled })}
          >
            Send Reset Instructions
          </button>
        </form>
        {successMessage?.length ? (
          <div className={cx('forgot-pass__success-message')}>
            <FontAwesomeIcon icon={faCircleCheck} />
            <span>{successMessage}</span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default withComponentName(ForgotPasswordCustomModalFinPrompt);
