import ChatbotComponents from '../../../../ChatbotComponents/chatbot';

import 'react-chatbot-kit/build/main.css';
import '../../../../ChatbotComponents/styles.css';
import '../../styles_chatbot.css';

const FinpromptChatbotComponent = (props) => (
  <ChatbotComponents
    {...props}
  />
);
export default FinpromptChatbotComponent;
