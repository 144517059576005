import PropTypes from 'prop-types';

import Styles from './styles.module.scss';

export const BetaTag = ({ className }) => (
  <span className={`${Styles['beta-tag']} ${className}`.trim()}>BETA</span>
);

BetaTag.propTypes = {
  className: PropTypes.string,
};

BetaTag.defaultProps = {
  className: '',
};

export default BetaTag;
